const constant = {
  
  // api: "http://localhost:8888/api/",
  // url: "http://localhost:8888/",

  url: "https://api.orient5.com/",
  api: "https://api.orient5.com/api/",

};
export default constant;
 