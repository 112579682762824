import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    // redirect: { name: "dashboard" },
    name: "home",
    meta: {
      is_auth: true,
      is_admin: true,
      title: "home",
    },
    component: () => import("../views/home.vue"),
    beforeEach: (to, from, next) => {
      next({ name: "home" });
    },
  },
 
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
