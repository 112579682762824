<template>
  <div class="vh-100">
    <div  style="z-index:10 ;" class="text-center">
      <img src="./assets/logo.jpg" height="60" alt="" class="animate__animated animate__bounce
            animate__delay-4s animate__slow "> 
    </div>
    
    <div class="">
      
      <div class="mt-5 mx-3 ">
        <router-view></router-view>
      </div>
    </div> 
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {};
  },
  
};
</script>

<style>

</style>
