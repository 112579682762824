import constant from "../../constant";
import axios from "axios";
const api = constant.api + "bookings_calendar";
// initial state
const state = () => ({
  reservations: [],
});

// getters
const getters = {
  
  getReservations: (state) => {
    return state.reservations;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let reservations = response.data;
      commit("SET_RESERVATIONS", reservations);
    });
  },

};

// mutations
const mutations = {
  SET_RESERVATIONS(state, reservations) {
    state.reservations = reservations;
  },


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
